import { ChangeEvent, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FormValues } from './interfaces';
import config from '../../config.json';
import Helper from '../../utils/Helper';
import { selectCartItems, selectCartZipcode, setCartZipcode } from '../../features/cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import CartObj from '../../features/cart/cartObj';
import { selectProducts } from '../../features/products/productsSlice';
import { selectToken } from '../../features/user/userSlice';
import GooglePlace from '../GooglePlace';

declare const _learnq: any;

export default function BillingDetails() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const formik = useFormikContext<FormValues>();
  const products = useSelector(selectProducts);
  const cartItems = useSelector(selectCartItems);
  const cartZipcode = useSelector(selectCartZipcode);
  const cart = useMemo(() => {
    return new CartObj(cartItems);
  }, [cartItems]);
  const [formValues, setFormValues] = useState<FormValues>();
  const excludeFields: Array<keyof FormValues> = [
    'password',
    'delivery_date',
    'shipping_method',
    'send_gift_card',
    'gift_card_email',
    'gift_card_msg',
    'cartTotal',
    'cardNumber',
    'cardExpiry',
    'cardCvc',
  ];

  const streetRef = useRef<any>();
  const townRef = useRef<any>();
  const [OPTMessage, setOPTMessage] = useState(formik?.values?.opt_in_marketing_text_messages);

  useEffect(() => {
    const storedValues = localStorage.getItem('FormValues');
    if (storedValues) {
      const parsedValues = JSON.parse(storedValues) as FormValues;
      setFormValues(parsedValues);
    }
  }, []);

  useEffect(() => {
    if (formValues && !token) {
      const updatedFormValues = { ...formValues };

      if (updatedFormValues.billing_postcode) {
        updatedFormValues.billing_postcode = cartZipcode;
      }

      const filteredValues: Pick<FormValues, Exclude<keyof FormValues, typeof excludeFields[number]>> = Object.fromEntries(
        Object.entries(updatedFormValues).filter(([key]) => !excludeFields.includes(key as keyof FormValues))
      );

      formik.setValues(filteredValues as FormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, token]);

  useEffect(() => {
    if (formik.values && cartZipcode && Boolean(!formik.values.use_shipping)) {
  
      const updatedFormValues = {
        ...formik.values,
        billing_postcode: cartZipcode,
      };
  
      formik.setValues(updatedFormValues as FormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartZipcode]);
  
  useEffect(() => {
    if (formik.values && !token) {
      handleFormValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, token]);

  useEffect(() => {
    if (formik.values.email) {
      const item = {
        CheckoutURL: `${process.env.REACT_APP_WEB_URL}product`,
        $value: cart.getSubtotal(null, null, null),
        items: cart.getItemsForGA(products)
      };

      if (typeof _learnq !== 'undefined') {
        _learnq.push(["track", "Started Checkout", item]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.email]);

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("phone", Helper.formatPhoneNo(e.target.value));
  }

  const handleFormValues = useCallback(async () => {
    try {
      await localStorage.setItem('FormValues', JSON.stringify(formik.values));
    } catch (error) {
      console.error('Error saving to local storage:', error);
    }
  }, [formik.values]);

  const handleOPTMsgChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOPTMessage(e.target.checked);
    formik.setFieldValue("opt_in_marketing_text_messages", e.target.checked);
  }

  const handleZipCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("billing_postcode", e.target.value);
    dispatch(setCartZipcode(e.target.value));
  }

  return (
    <Card className='billing-details my-3' body>
      <div className='my-4 fs-3'>Billing Details</div>
      <Row>
        <Col sm={12} lg={6}>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              id="email"
              type="email"
              isInvalid={Boolean(formik.errors.email)}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.errors.email &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col sm={12} lg={6}>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Mobile Phone</Form.Label>
            <Form.Control
              id="phone"
              type="text"
              placeholder="For delivery confirmation"
              maxLength={14}
              isInvalid={Boolean(formik.errors.phone)}
              value={formik.values.phone}
              onChange={handlePhoneChange}
            />
            {formik.errors.phone &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone}
              </Form.Control.Feedback>}
            <Form.Check
              type="checkbox"
              name="product-diet-restrictions"
              label="Opt-in to marketing text messages"
              id="opt_in_marketing_text_messages"
              checked={OPTMessage}
              onChange={handleOPTMsgChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              id="billing_first_name"
              type="text"
              isInvalid={Boolean(formik.errors.billing_first_name)}
              value={formik.values.billing_first_name}
              onChange={formik.handleChange}
            />
            {formik.errors.billing_first_name &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.billing_first_name}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col sm={12} lg={6}>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              id="billing_last_name"
              type="text"
              isInvalid={Boolean(formik.errors.billing_last_name)}
              value={formik.values.billing_last_name}
              onChange={formik.handleChange}
            />
            {formik.errors.billing_last_name &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.billing_last_name}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3 form-group required">
        <Form.Label>Street address</Form.Label>
        <GooglePlace formik={formik} streetRef={streetRef} townRef={townRef} name={'billing_address_1'}
          city={'billing_city'} state={'billing_state'} postcode={'billing_postcode'}
        />
        {formik.errors.billing_address_1 &&
          <Form.Control.Feedback type="invalid">
            {formik.errors.billing_address_1}
          </Form.Control.Feedback>}
      </Form.Group>
      <Form.Group className="mb-3 form-group">
        <Form.Control
          id="billing_address_2"
          type="text"
          placeholder="Apartment, suite, unit etc. (optional)"
          isInvalid={Boolean(formik.errors.billing_address_2)}
          value={formik.values.billing_address_2}
          onChange={formik.handleChange}
        />
        {formik.errors.billing_address_2 &&
          <Form.Control.Feedback type="invalid">
            {formik.errors.billing_address_2}
          </Form.Control.Feedback>}
      </Form.Group>
      <Form.Group className="mb-3 form-group required">
        <Form.Label>Town / City</Form.Label>
        <Form.Control
          id="billing_city"
          ref={townRef}
          type="text"
          name="billing_city"
          placeholder="Enter Town/Area"
          value={formik.values.billing_city}
          onChange={formik.handleChange}
        />
        {formik.errors.billing_city &&
          <Form.Control.Feedback type="invalid">
            {formik.errors.billing_city}
          </Form.Control.Feedback>}
      </Form.Group>
      <p>Delivery is to Residential addresses only. Non-residential deliveries
        will be canceled and are not refundable.
      </p>
      <Row>
        <Col>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>State</Form.Label>
            <Form.Select
              id="billing_state"
              isInvalid={Boolean(formik.errors.billing_state)}
              value={formik.values.billing_state}
              onChange={formik.handleChange}
            >
              <option value="">-- select a state --</option>
              {config.STATE_CODES.map((entry) => (
                <option key={entry.code} value={entry.code}>{entry.state}</option>
              ))}
            </Form.Select>
            {formik.errors.billing_state &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.billing_state}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Postcode / ZIP</Form.Label>
            <Form.Control
              id="billing_postcode"
              type="text"
              maxLength={10}
              placeholder="Enter Postcode / ZIP"
              value={formik.values.billing_postcode}
              onChange={handleZipCodeChange}
            />
            {formik.errors.billing_postcode &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.billing_postcode}
              </Form.Control.Feedback>}
          </Form.Group>
        </Col>
      </Row>
    </Card>
  )
}