import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { selectGeneralOptions, selectIsMobileRoute, selectUtmMinimum } from '../../features/mobile/mobileSlice';
import { selectCartItems, selectCartZipcode, selectOrderInProgress } from '../../features/cart/cartSlice';
import CartObj from '../../features/cart/cartObj';
import { useNavigate } from 'react-router-dom';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import { FormValues } from './interfaces';
import { useFormikContext } from 'formik';

export default function ModalPopups() {
    const navigate = useNavigate();
    const formik = useFormikContext<FormValues>();
    const [regionalShipLimit, setRegionalShipLimit] = useState(0);
    const orderInProgress = useSelector(selectOrderInProgress);
    const AppURL = useSelector(selectIsMobileRoute);
    const cartItems = useSelector(selectCartItems);
    const shippingMethods = useSelector(selectShippingMethods);
    const cartZipcode = useSelector(selectCartZipcode);
    const utmMinimum = useSelector(selectUtmMinimum);
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const cart = useMemo(() => {
        return new CartObj(cartItems);
    }, [cartItems]);
    const QURLParams = AppURL ? `/?${AppURL}` : '/';
    const shippingMethod = ShippingMethodObj.getById(
        shippingMethods,
        Number(formik.values.shipping_method)
    );

    useEffect(() => {
        async function fetchData() {
            try {
                if (shippingMethod && shippingMethod.data.ID) {
                    if (getGeneralOptions) {
                        const { regional_shipping_meal_limit } = getGeneralOptions;

                        const isUPSOrUPSFreeShipping = shippingMethod?.data.ups_delivery_method === true;

                        const isRegionalMinOverrides = isUPSOrUPSFreeShipping && utmMinimum && utmMinimum?.minimum_value;
                        const isRegionalMealLimit = isUPSOrUPSFreeShipping && regional_shipping_meal_limit;

                        const shipLimit = isRegionalMinOverrides ? Number(utmMinimum?.minimum_value)
                            : (isRegionalMealLimit ? Number(regional_shipping_meal_limit) : 0);

                        setRegionalShipLimit(shipLimit);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [shippingMethods, cartZipcode, getGeneralOptions, utmMinimum, shippingMethod]);

    return (
        <>
            {(regionalShipLimit > 0 && regionalShipLimit > cart.getItemCount() && !orderInProgress) &&
                <Modal show={true} backdrop="static">
                    <Modal.Body className='font-barlow mb-4'>
                        <div className='text-center fs-4'>
                            <p className='mt-2'>Shipping requires a minimum of {regionalShipLimit} meals.
                                Please add {regionalShipLimit} meals to your cart to continue to checkout</p>
                        </div>
                        <div className='d-flex justify-content-center p-3'>
                            <Button variant="dark" onClick={() => { navigate(`/order${QURLParams}`) }}>Add Items To Cart</Button>
                        </div>
                    </Modal.Body>
                </Modal>}
        </>
    )
}
