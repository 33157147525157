import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import AutoShipPage from './AutoShipPage'
import AutoShipSettings from './components/AutoShipSettings'
import AutoshipOrders from './components/AutoshipOrders'
import AutoshipOrderPage from './components/AutoshipOrderPage'
import AutoshipMenuPage from './components/AutoshipMenuPage'
import { selectToken } from '../../features/user/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import MyAccountPage from '../MyAccountPage'
import { setPauseAutoship, setVideoTutorials } from './core/autoShipSlice'
import { selectCustomer } from '../../features/customer/customerSlice'
import { loadOrderPlannerOrders, selectOrderPlannerOrdersPagesLoaded } from './core/orderPlannerOrdersSlice'
import AutoShipAPI from './core/autoshipAPI'

const AutoshipWrapper = () => {
  const dispatch = useDispatch();
  const token: string = useSelector(selectToken);
  const custData = useSelector(selectCustomer);
  const orderPlannerPagesLoaded: number = useSelector(selectOrderPlannerOrdersPagesLoaded);

  useEffect(() => {
    if (custData && custData?.meta_data) {
      const metakey = custData?.meta_data?.find((meta: any) => meta.key === "user_pause_order_planner");
      if (metakey?.value) {
        const isPaused = metakey?.value === "1" ? true : false;
        dispatch(setPauseAutoship(isPaused));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custData, custData?.meta_data])

  useEffect(() => {
    const handleOrderPlanner = async () => {
      if (token && !orderPlannerPagesLoaded && custData.id) {
        try {
          dispatch(loadOrderPlannerOrders(custData.id));
          const response = await AutoShipAPI.getVideoTutorials();
          dispatch(setVideoTutorials(response));
        } catch (error) {
          console.error("Failed to fetch video tutorials:", error);
        }
      }
    };

    handleOrderPlanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, orderPlannerPagesLoaded, custData.id]);

  if (!token) {
    return (
      <MyAccountPage />
    )
  }

  return (
    <Routes>
      <Route index element={<AutoShipPage />} />
      <Route path="/settings" element={<AutoShipSettings />} />
      <Route path="/settings/:orderId" element={<AutoShipSettings />} />
      <Route path="/orders" element={<AutoshipOrders />} />
      <Route path="/menu" element={<AutoshipMenuPage />} />
      <Route path="/menu/:orderId" element={<AutoshipMenuPage />} />
      <Route path="/orders/:orderId" element={<AutoshipOrderPage />} />
    </Routes>
  )
}

export default AutoshipWrapper;
